export function advancedSearchResultsQuery(
  siteName: string,
  coveoSiteName: string,
  coveoProductSource: string,
  coveoSitecoreSource: string,
  locale: string,
  isUserAllowedToAccessPreLaunch: boolean
) {
  const excludePreLaunchProducts = `NOT @isprelaunch=="true"`;
  let andExcludePreLaunchProductsIfUserIsNotAllowed = '';
  if (!isUserAllowedToAccessPreLaunch) {
    // If user is not allowed to access pre-launch products (or not logged in), filter them out
    andExcludePreLaunchProductsIfUserIsNotAllowed = `AND ${excludePreLaunchProducts}`;
  }

  return `
    @z95xlanguage=="${locale}"
    AND (
      (
        @source=="${coveoProductSource}" AND @tenant=="${siteName}"
        AND (
          ( @z95xtemplatename=="Accessory" AND NOT @isphasedout=="true" AND ${excludePreLaunchProducts} )
          OR (
            NOT @z95xtemplatename=="Accessory"
            AND (
              (@herotemplatename=="Product" ${andExcludePreLaunchProductsIfUserIsNotAllowed})
              OR ( @herotemplatename=="Variant" AND ${excludePreLaunchProducts} )
            )
          )
        )
      )
      OR (@source=="${coveoSitecoreSource}" AND (@site=="${coveoSiteName}" OR @storyz32xbrands="${coveoSiteName}" OR @z95xtemplatename=="Job"))
    )
  `;
}
