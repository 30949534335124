import { KnownSiteNames, KnownSiteNamesType } from './../../Constants/KnownSiteNames';
import { Raw } from '@coveo/headless';
import { getCoveoProductsSource } from '../../AppGlobals';
import { CoveoResultItemUrls } from './CoveoSettings';

export type CoveoRaw = Raw & {
  mainpicture320?: string;
  imgsrc?: string;
  pimclassificationtranslated?: string[];
  category?: string;
  masterproductname?: string;
  productassetnamenormalized?: string;
  isphasedout?: string;
  isprelaunch?: string;
  masterproductrelativeurl?: string;
  ez120xpertisepageherotitle?: string;
  solutionpageherotitle?: string;
  title?: string;
  storypageherotitle?: string;
  careerz32xpagez32xtitle?: string;
  universalpageherotitle?: string;
  itemurlaz120xetris?: string;
  itemurlleistergroup?: string;
  itemurlleistertechnologies?: string;
};

export const isArticle = (raw: Raw): boolean => raw.variantarticlenumber !== undefined;
export const isProduct = (raw: Raw): boolean => {
  const coveoProductsSource = getCoveoProductsSource();

  return raw.source === coveoProductsSource;
};
export const isExpertiseHero = (raw: Raw): boolean => raw.ez120xpertisepageherotitle !== undefined;
export const isSolution = (raw: Raw): boolean => raw.solutionpageherotitle !== undefined;
export const isStory = (raw: Raw): boolean => raw.storypageherotitle !== undefined;
export const isUniversalStory = (raw: Raw): boolean => raw.z95xtemplatename === 'Story';
export const isCareer = (raw: Raw): boolean => raw.careerz32xpagez32xtitle !== undefined;
export const isUniversalCareer = (raw: Raw): boolean => raw.z95xtemplatename === 'Job';
export const isEvent = (raw: Raw): boolean => raw.eventz32xtitle !== undefined;
export const isUniversal = (raw: Raw): boolean => raw.universalpageherotitle !== undefined;
export const isProdcutDownload = (raw: Raw): boolean => raw.z95xtemplatename === 'product';
export const isLeisterTechnologies = (siteName: KnownSiteNamesType): boolean =>
  siteName === KnownSiteNames.LeisterTechnologies;
export const isLeisterGroup = (siteName: KnownSiteNamesType): boolean => siteName === KnownSiteNames.LeisterGroup;
export const isAxetris = (siteName: KnownSiteNamesType): boolean => siteName === KnownSiteNames.Axetris;

export const getResultImageSrc = (raw: CoveoRaw): string | undefined => {
  if (isArticle(raw)) {
    return raw.mainpicture320;
  }

  if (isProduct(raw)) {
    return raw.mainpicture320;
  }

  return raw.imgsrc;
};

export const getResultCategory = (raw: CoveoRaw): string | undefined => {
  if (isArticle(raw)) {
    return raw.pimclassificationtranslated?.join(', ');
  }

  if (isProduct(raw)) {
    return raw.pimclassificationtranslated?.join(', ');
  }

  return raw.category;
};

export const getResultTitle = (raw: CoveoRaw): string => {
  if (isArticle(raw)) {
    return `${raw.variantnametranslated} ${raw.variantarticlenumberraw}`;
  }

  if (isProduct(raw)) {
    return raw.masterproductname ?? '';
  }

  if (isExpertiseHero(raw)) {
    return raw.ez120xpertisepageherotitle ?? '';
  }

  if (isSolution(raw)) {
    return raw.solutionpageherotitle ?? '';
  }

  if (isStory(raw)) {
    return raw.storypageherotitle ?? '';
  }

  if (isUniversalStory(raw)) {
    return raw.title ?? '';
  }

  if (isCareer(raw)) {
    return raw.careerz32xpagez32xtitle ?? '';
  }

  if (isUniversal(raw)) {
    return raw.universalpageherotitle ?? '';
  }

  return raw.title ?? '';
};

export const getResultUrl = (raw: CoveoRaw, siteName: KnownSiteNamesType): string => {
  if (isArticle(raw)) {
    return raw.masterproductrelativeurl ?? '';
  }

  if (isProduct(raw)) {
    return raw.masterproductrelativeurl ?? '';
  }

  return raw[CoveoResultItemUrls[siteName]] as string;
};
