import { StandaloneSearchBoxAnalytics, buildTab, loadQueryActions } from '@coveo/headless';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import SearchResultHeading from '../SearchResultHeading';
import SearchResultTabs from '../SearchResultTabs';
import { SearchResultList } from '../SearchResultList';
import { FacetManager } from '../FacetManager';
import { SearchEngineContext } from '../context/SearchEngineContext';
import classNames from 'classnames';
import { SearchContext } from '../context/SearchContext';
import FloatingFilterToggle from '../FloatingFilterToggle';
import FacetsModal from '../FacetsModal';
import { getCoveoProductsSource, getCoveoSitecoreSource } from '../../../AppGlobals';
import { CoveoSearchPageFacets, SearchResultsTabDefinitions } from '../CoveoSettings';
import { useCoveoQuery } from '../CoveoQuery';
import CategorFacet from '../CategoryFacet';
import { CoveoContext } from '../context/CoveoContext';
import { advancedSearchResultsQuery } from './advancedSearchResultsQuery';

type SearchResultsProps = {
  downloadPagePath?: string;
  isUserAllowedToAccessPreLaunch: boolean;
};

type SearchResultsTab = {
  controller: ReturnType<typeof buildTab>;
  caption: string;
  formatCount: (count: number) => string;
  id: string;
  value: string[];
};

export const SearchResultsComponent: React.FC<SearchResultsProps> = ({
  downloadPagePath,
  isUserAllowedToAccessPreLaunch
}) => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, coveoSiteName, locale }
  } = useContext(SearchEngineContext);
  const { state: searchState } = useContext(SearchContext);
  const coveoProductSource = getCoveoProductsSource();
  const coveoSitecoreSource = getCoveoSitecoreSource();

  const advancedQuery = advancedSearchResultsQuery(
    siteName,
    coveoSiteName,
    coveoProductSource,
    coveoSitecoreSource,
    locale,
    isUserAllowedToAccessPreLaunch
  );

  const { buildFacetController, querySummaryController } = useCoveoQuery({
    engine,
    aq: advancedQuery
  });
  const [state, setState] = useState(querySummaryController.state);

  const executeFirstSearchAfterStandaloneSearchBoxRedirect = useCallback(
    (data: string) => {
      localStorage.removeItem('standaloneSearchBoxStorageKey');

      const parsed: {
        value: string;
        analytics: StandaloneSearchBoxAnalytics;
      } = JSON.parse(data);

      const { value, analytics } = parsed;
      const { updateQuery } = loadQueryActions(engine);

      engine.dispatch(updateQuery({ q: value }));
      engine.executeFirstSearchAfterStandaloneSearchBoxRedirect(analytics);
    },
    [engine]
  );

  const executeInitialSearch = useCallback(() => {
    const data = localStorage.getItem('standaloneSearchBoxStorageKey');

    if (data) {
      executeFirstSearchAfterStandaloneSearchBoxRedirect(data);

      return;
    }

    engine.executeFirstSearch();
  }, [engine, executeFirstSearchAfterStandaloneSearchBoxRedirect]);

  useEffect(() => {
    executeInitialSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [querySummaryController, executeInitialSearch]);

  const tabs = useMemo(() => {
    const forceVariantsTab = /^\d{3}\.\d{3}$/.test(state.query);

    const searchResultTabs = SearchResultsTabDefinitions[siteName].map(tab => ({
      controller: buildTab(engine, {
        initialState: {
          // Check if the variant tab should be selected, otherwise use the default tab from the config.
          isActive:
            (forceVariantsTab && tab.value.includes('variant')) || (!forceVariantsTab && tab.defaultActive) || false
        },
        options: {
          id: tab.id,
          expression: tab.expression
        }
      }),
      caption: tab.caption(),
      formatCount: (count: number) => `(${count})`,
      id: tab.id,
      value: tab.value
    }));

    // Handle Variant tab feature (when searching for article numbers)
    if (forceVariantsTab) {
      // Instead of the last tab, get the variant tab and move it to the first position
      const variantTab = searchResultTabs.find(tab => tab.value.includes('variant'));
      if (variantTab) {
        const variantTabIndex = searchResultTabs.indexOf(variantTab);
        searchResultTabs.splice(variantTabIndex, 1);
        searchResultTabs.unshift(variantTab);

        // Make sure the variant tab is selected and results are updated
        variantTab.controller.select();
      }
    }

    return searchResultTabs;
  }, [state.query, siteName, engine]);

  useEffect(() => {
    if (state.firstSearchExecuted) {
      let firstTabWithResults: SearchResultsTab | null = null;
      let activeTabWithoutResults = false;

      tabs.forEach(tab => {
        const hasResults =
          searchState?.tabResultsCount?.reduce((acc, curr) => {
            return tab.value.includes(curr.value) ? acc + curr.numberOfResults : acc;
          }, 0) > 0;

        if (hasResults && firstTabWithResults === null) {
          firstTabWithResults = tab;
        }

        if (tab.controller.state.isActive && !hasResults) {
          activeTabWithoutResults = true;
        }
      });

      if (activeTabWithoutResults && firstTabWithResults) {
        (firstTabWithResults as SearchResultsTab).controller.select();
      }
    }
  }, [state.firstSearchExecuted, tabs, searchState.tabResultsCount]);

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <FloatingFilterToggle />
      <SearchResultHeading query={state.query} />
      <div className='SearchResults__Main'>
        <SearchResultTabs tabs={tabs} />
        <FacetsModal hideOnDesktop={true} foldable={false} />
        <FacetManager
          staticOnDesktop={true}
          engine={engine}
          hasResults={(state.hasResults || state.firstSearchExecuted) && !state.isLoading}
        >
          {CoveoSearchPageFacets[siteName].map(facet => {
            const facetController = buildFacetController(facet.field);

            return facet.tabs.includes(searchState.activeTab) ? (
              <CategorFacet key={facet.field} controller={facetController} title={facet.label()} id={facet.field} />
            ) : (
              <React.Fragment key={facet.field} />
            );
          })}
        </FacetManager>
        {state.hasResults && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList
          engine={engine}
          query={state.query}
          siteName={siteName}
          language={locale}
          downloadPagePath={downloadPagePath}
        />
      </div>
    </div>
  );
};

export default SearchResultsComponent;
