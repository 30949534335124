import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../../SearchResultList';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { getCoveoProductsSource } from '../../../../../AppGlobals';
import { useCoveoQuery } from '../../../CoveoQuery';
import { SearchContext } from '../../../context/SearchContext';
import { CoveoContext } from '../../../context/CoveoContext';

export const MicroOpticsListerComponent = () => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);
  const { state: searchState } = useContext(SearchContext);
  const coveoProductSource = getCoveoProductsSource();

  const advancedQuery = `@source=="${coveoProductSource}" AND @tenant=="${siteName}" AND @z95xlanguage==${locale} AND NOT @isphasedout=="true" AND NOT @isprelaunch=="true"`;

  const { querySummaryController } = useCoveoQuery({
    engine,
    aq: advancedQuery,
    cq: `@pimclassification=="Micro-Optics"`
  });
  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine]);

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <div className='SearchResults__Main'>
        {(state.hasResults || state.firstSearchExecuted) && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList
          engine={engine}
          layout={searchState.layout}
          query={state.query}
          siteName={siteName}
          language={locale}
        />
      </div>
    </div>
  );
};

export default MicroOpticsListerComponent;
