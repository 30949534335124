import { LANG, ONE_YEAR } from '../components/Common/CookieSettings/CookieConstants';
import { setCookie, getCookie } from '../components/Common/CookieSettings/CookieUtils';
import stripHtml from 'string-strip-html';
import { ANONYMOUS_STRING, LOGIN_LINK, LOGOUT_LINK } from '../components/Common/GeneralConstants/GeneralConstants';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

/**
 * Return the sitecore id to lowercase and without braces and
 * dashes if sitecoreId is a string or null if it is not a string.
 *
 * @param {string} sitecoreId
 * @return {string or null}
 */
export const normalizeSitecoreId = sitecoreId => {
  return typeof sitecoreId === 'string' ? sitecoreId.toLowerCase().replace(/{|}|-/g, '') : null;
};

/**
 * Return the text without white spaces
 *
 * @param {string} text
 * @return {string or null}
 */
export const removeWhiteSpaces = text => {
  return typeof text === 'string' ? text.replace(/\s/g, '') : null;
};

/**
 * Set language to cookies and to sitecore
 * @param {string} language
 */
export const setLanguage = language => {
  setCookie(LANG, language, ONE_YEAR);
};

/**
 * get verify_email_redirect, ui_locales and country_code using referer from request
 */
export const getRefererData = () => {
  const req = new XMLHttpRequest();

  req.open('GET', document.location, false);
  req.send(null);

  const data = {};
  const referer = document.referrer;
  data['Referer'] = referer;

  for (let key in data) {
    if (key !== '' && key === 'Referer') {
      return {
        verify_email_redirect: extractParam('verify_email_redirect', data[key]),
        ui_locales: extractParam('ui_locales', data[key]),
        country_code: extractParam('country_code', data[key])
      };
    }
  }

  return null;
};

/**
 * get a param value from a string
 */
export const extractParam = (name, value) => {
  const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(value);
  if (results == null) {
    return null;
  } else {
    return results[1] || 0;
  }
};

/**
 * extract parameters from JWT token
 * @param {*} JWT token format param
 * @return Object with the extracted parameters
 */
export const parseJwt = token => {
  if (token && token !== '') {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  return null;
};

/**
 * Function that converts from one file unit size to another
 *
 * @param {number} size size in from unit
 * @param {string} fromUnit  current unit of size
 * @param {string} toUnit unit that we want
 *
 * @return {float}
 */
export const fileSizeConverter = (size, fromUnit, toUnit) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const from = units.indexOf(fromUnit.toUpperCase());
  const to = units.indexOf(toUnit.toUpperCase());
  const BASE_SIZE = 1024;
  let result = 0;

  if (from < 0 || to < 0) {
    return (result = 'Error: Incorrect units');
  }

  result = from < to ? size / BASE_SIZE ** to : size * BASE_SIZE ** from;

  return result.toFixed(2);
};

/**
 * @method cutStringWithDots
 * @description take a string and return it cutted at the indexes requested or null if the stringToCut is not a string
 *
 * @param {string} stringToCut the string that will be cutted
 * @param {number} until until which index to go
 * @param {number} from from which index to start
 * @returns string or null
 */
export const cutStringWithDots = (stringToCut, until, from = 0) => {
  if (typeof stringToCut === 'string') {
    let newString = stringToCut.substring(from, until);
    return newString + (stringToCut.length > until ? '...' : '');
  }
  return null;
};

/**
 * @method cutHtmlStringWithDots
 * @description take a string and return it cutted at the indexes requested or null if the stringToCut is not a string
 *
 * @param {string} stringToCut the string that will be cutted
 * @param {number} until until which index to go
 * @param {number} from from which index to start
 * @returns string or null
 */
export const cutHtmlStringWithDots = (stringToCut, until, from = 0) => {
  if (typeof stringToCut === 'string') {
    const strippedString = stripHtml(stringToCut || ''),
      newString = strippedString.substring(from, until);
    return newString + (strippedString.length > until ? '...' : '');
  }
  return null;
};

export const generateLink = language => {
  let path = '';
  const urlLang = language || getCookie(LANG);
  if (typeof window !== 'undefined') {
    const pathName = window.location.pathname,
      splitPath = pathName.split('/');

    splitPath.shift();
    if (splitPath[0].length === 2) {
      splitPath[0] = urlLang;
    } else {
      splitPath.unshift(urlLang);
    }

    path = splitPath.length > 0 ? splitPath.join('/') : '';
  }

  return {
    value: {
      href: '/' + path
    }
  };
};

function fireEvent(element, event) {
  if (document.createEventObject) {
    // dispatch for IE
    var evt = document.createEventObject();
    return element.fireEvent('on' + event, evt);
  } else {
    // dispatch for firefox + others
    var ev = document.createEvent('HTMLEvents');
    ev.initEvent(event, true, true); // event type,bubbling,cancelable
    return !element.dispatchEvent(ev);
  }
}

/**
 * @method setValueInSelect
 * @description  set a value on an element
 * @param {string} elementSetValue the string that will be set as element value
 * @param  element html element that has de value
 */

export function setValueInSelect(element, elementSetValue) {
  if (element) {
    element.value = elementSetValue;
    fireEvent(element, 'change');
  }
}

/**
 * @method setValueInput
 * @description  set a value on an element
 * @param {string} elementSetValue the string that will be set as element value
 * @param  element html element that has de value
 */

export function setValueInput(element, elementSetValue) {
  if (element) {
    (function () {
      if (typeof window.CustomEvent === 'function') return false;

      function CustomEvent(event, params) {
        params = params || {
          bubbles: false,
          cancelable: false,
          detail: null
        };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      window.CustomEvent = CustomEvent;
    })();

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;

    nativeInputValueSetter.call(element, elementSetValue);
    let ev = window.CustomEvent ? new CustomEvent('input', { bubbles: true }) : new Event('input', { bubbles: true });
    element.dispatchEvent(ev);
    element.value = elementSetValue;

    element.dispatchEvent(ev);
  }
}

/**
 * Check if the current page is open in experience editor mode or in normal mode
 */
export function isPageEditor() {
  const SitecoreVar = typeof window !== 'undefined' && window.Sitecore ? window.Sitecore : null;
  if (!SitecoreVar) {
    return false;
  }

  return (
    !!(
      typeof SitecoreVar !== 'undefined' &&
      typeof SitecoreVar.PageModes !== 'undefined' &&
      typeof SitecoreVar.PageModes.PageEditor !== 'undefined'
    ) ||
    (document.body && document.body.getAttribute('class') === 'sc-edit-mode')
  );
}

export const checkIfUserIsAllowedToAccessPreLaunch = sitecoreContext => {
  if (checkIfUserIsLoggedIn(sitecoreContext) && sitecoreContext?.productLifecycle?.userCanAccessPreLaunchProducts) {
    return true;
  }
  return false;
};

export const checkIfUserIsLoggedIn = sitecoreContext => {
  if (
    sitecoreContext &&
    sitecoreContext.user &&
    sitecoreContext.user.name !== ANONYMOUS_STRING &&
    !isExperienceEditorActive()
  ) {
    return true;
  }
  return false;
};

export const logInRedirect = () => {
  if (!isExperienceEditorActive()) {
    window.location.href = LOGIN_LINK;
  }
};

export const logOutRedirect = () => {
  if (!isExperienceEditorActive()) {
    window.location.href = LOGOUT_LINK;
  }
};

export const getCurrentArticleNumber = function (props) {
  const { match } = props,
    relativePath = typeof window !== 'undefined' ? window.location.pathname : '',
    matchArticleNumber = match && match.params && match.params.article_number ? match.params.article_number : null,
    historyStateArticleNumber =
      typeof window !== 'undefined' && window.history.state && window.history.state.articleNr
        ? window.history.state.articleNr
        : null;

  if (matchArticleNumber && relativePath.indexOf(matchArticleNumber) > 0) {
    return matchArticleNumber;
  } else if (historyStateArticleNumber && relativePath.indexOf(historyStateArticleNumber) > 0) {
    return historyStateArticleNumber;
  }
};

export const addProductOnDataLayer = (productID, productName, productCategory, productUrl, pimbrandname) => {
  if (typeof window !== 'undefined') {
    window.addProductOnDataLayerOnClick(productID, productName, productCategory, productUrl, pimbrandname);
  }
};

export const getSmallestImageCrop = srcSetValue => {
  if (srcSetValue) {
    return srcSetValue.split(' 320w')[0];
  }
  return null;
};

export const get768ImageFromSrcSet = srcSetValue => {
  if (srcSetValue) {
    const firstSplit = srcSetValue.split(' 320w,');
    if (firstSplit && firstSplit[1]) {
      return firstSplit[1].split(' 768w,')[0];
    }
  }
};

export const trackProductImpressions = listOfProducts => {
  const dataLayer = window.dataLayer || [],
    indexEcommerceDataLayer = dataLayer.findIndex(element => element.ecommerce);
  if (!listOfProducts && indexEcommerceDataLayer >= 0) {
    dataLayer[indexEcommerceDataLayer].ecommerce = null; // Clear the previous ecommerce object.
    return;
  }
  if (indexEcommerceDataLayer >= 0) {
    if (!dataLayer[indexEcommerceDataLayer].ecommerce.impressions) {
      dataLayer[indexEcommerceDataLayer].ecommerce.impressions = listOfProducts;

      return;
    }
    dataLayer[indexEcommerceDataLayer].ecommerce.impressions = [
      ...dataLayer[indexEcommerceDataLayer].ecommerce.impressions,
      ...listOfProducts
    ];
    return;
  }

  dataLayer.push({
    ecommerce: {
      impressions: listOfProducts
      //  exemple
      // 	{
      // 		name: 'Triblend Android T-Shirt', // Name or ID is required.
      // 		id: '12345',
      // 		price: '15.25',
      // 		brand: 'Google',
      // 		category: 'Apparel',
      // 		variant: 'Gray',
      // 		list: 'Search Results',
      // 		position: 1
      // 	}
    }
  });
};
