import { Result, SearchEngine, buildResultList, buildResultsPerPage } from '@coveo/headless';
import React, { useEffect, useMemo, useState } from 'react';
import {
  isArticle,
  isCareer,
  isEvent,
  isExpertiseHero,
  isProduct,
  isSolution,
  isStory,
  isUniversal,
  isUniversalCareer,
  isUniversalStory
} from '../ResultItemUtils';
import ProductResultItem from '../ResultItem/ProductResultItem';
import SearchCareerResultItem from '../ResultItem/SearchCareerResultItem';
import StoryResultItem from '../ResultItem/StoryResultItem';
import ExpertiseResultItem from '../ResultItem/ExpertiseResultItem';
import SolutionResultItem from '../ResultItem/SolutionResultItem';
import HeroResultItem from '../ResultItem/HeroResultItem';
import { LoadMore } from '../LoadMore';
import NoResults from '../NoResults';
import EventResultItem from '../ResultItem/EventResultItem';
import classNames from 'classnames';
import CareerResultItem from '../ResultItem/CareerResultItem';
import UniversalStoryResultItem from '../ResultItem/UniversalStoryResultItem';
import UniversalSearchCareerResultItem from '../ResultItem/UniversalSearchCareerResultItem';
import UniversalCareerResultItem from '../ResultItem/UniversalCareerResultItem';
import type { SearchResultItemProps } from '../ResultItem/ProductResultItem/types';
import { type KnownSiteNamesType } from '../../../Constants/KnownSiteNames';
import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../Common/CookieSettings/CookieConstants';

type SearchResultListProps = {
  siteName: KnownSiteNamesType;
  language: string;
  query: string;
  layout?: 'List' | 'Card';
  engine: SearchEngine;
  noResultsText?: string;
  className?: string;
  ItemRendering?: React.FC<SearchResultItemProps>;
  downloadPagePath?: string;
};

export const SearchResultListFields = [
  'careerz32xpagez32xtitle',
  'category',
  'country',
  'date',
  'eventz32xcity',
  'eventz32xcountry',
  'eventz32xdetailsz32xlinkz32xtez120xt',
  'eventz32xendz32xdate',
  'eventz32xindustries',
  'eventz32xstartz32xdate',
  'eventz32xtitle',
  'eventz32xvenue',
  'ez120xpertisepageherotitle',
  'image',
  'imgsrc',
  'itemurlaz120xetris',
  'itemurlleistergroup',
  'itemurlweldy',
  'itemurlleistertechnologies',
  'linkerz32xbuttonz32xlabel',
  'location',
  'mainpicture',
  'mainpicture320',
  'masterproductname',
  'productassetnamenormalized',
  'masterproductrelativeurl',
  'pimbrandname',
  'pimclassificationinitialen',
  'pimclassificationtranslated',
  'pimmetadescription',
  'pimmetasubdescription',
  'pimproductid',
  'solutionpageherotitle',
  'storypageherolinkerbuttonlabel',
  'storypageherotitle',
  'tez120xt',
  'title',
  'variantarticlenumber',
  'variantarticlenumberraw',
  'variantname',
  'variantnametranslated',
  'workz32xload',
  'z95xlanguage',
  'universalbuttondescription',
  'storyz32ximage',
  'storyz32xoverline',
  'storyz32xdate',
  'universalpageherotitle',
  'z95xtemplatename',
  'jobz32ximage',
  'jobz32xtitle',
  'company',
  'herotemplatename',
  'isphasedout',
  'isprelaunch',
  'pimarticlenumbers',
  'pimamountarticles',
  'target_article_number',
  'pimproducttype'
];

export const SearchResultList: React.FC<SearchResultListProps> = ({
  engine,
  siteName,
  query,
  noResultsText,
  layout = 'Card',
  className,
  ItemRendering,
  downloadPagePath,
  language
}) => {
  const countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase() || 'de';
  const fieldsToInclude = useMemo(() => {
    return [
      ...SearchResultListFields,
      `shopify_${countryCode}_items_in_shop`,
      `shopify_${countryCode}_target_article_number`,
      `shopify_${countryCode}_cheapest_price`,
      `shopify_${countryCode}_currency_code`,
      `shopify_${countryCode}_has_demo_device`,
      `shopify_${countryCode}_has_bundle`,
      `shopify_${countryCode}_largest_sale`,
      `shopify_${countryCode}_gids`
    ];
  }, [countryCode]);

  const resultListController = useMemo(
    () =>
      buildResultList(engine, {
        options: {
          fieldsToInclude
        }
      }),
    [engine, fieldsToInclude]
  );

  buildResultsPerPage(engine, {
    initialState: { numberOfResults: 30 }
  });

  const [state, setState] = useState(resultListController.state);
  const [showLoadMore, setShowLoadMore] = useState(false);

  useEffect(
    () =>
      resultListController.subscribe(() => {
        setState(resultListController.state);
        setShowLoadMore(resultListController.state.moreResultsAvailable);
      }),
    [resultListController]
  );

  const loadMore = () => {
    resultListController.fetchMoreResults();
  };

  const getResultItem = (result: Result, siteName: KnownSiteNamesType, engine: SearchEngine) => {
    const { raw } = result;

    if (isArticle(raw) || isProduct(raw)) {
      return (
        <ProductResultItem
          result={result}
          engine={engine}
          downloadPagePath={downloadPagePath}
          language={language}
          siteName={siteName}
        />
      );
    }

    if (isExpertiseHero(raw)) {
      return <ExpertiseResultItem result={result} engine={engine} siteName={siteName} />;
    }

    if (isSolution(raw)) {
      return <SolutionResultItem result={result} engine={engine} siteName={siteName} />;
    }

    if (isUniversalStory(raw)) {
      return <UniversalStoryResultItem result={result} engine={engine} siteName={siteName} />;
    }

    if (isUniversal(raw)) {
      return <HeroResultItem result={result} engine={engine} siteName={siteName} />;
    }

    if (isStory(raw)) {
      return <StoryResultItem result={result} engine={engine} siteName={siteName} />;
    }

    if (isUniversalCareer(raw)) {
      return layout === 'Card' ? (
        <UniversalSearchCareerResultItem result={result} engine={engine} siteName={siteName} />
      ) : (
        <UniversalCareerResultItem result={result} engine={engine} siteName={siteName} />
      );
    }

    if (isCareer(raw)) {
      return layout === 'Card' ? (
        <SearchCareerResultItem result={result} engine={engine} siteName={siteName} />
      ) : (
        <CareerResultItem result={result} engine={engine} siteName={siteName} />
      );
    }

    if (isEvent(raw)) {
      return <EventResultItem result={result} engine={engine} siteName={siteName} />;
    }

    return null;
  };

  return (
    <>
      <div className={classNames('SearchResultList', className)}>
        {!state.results.length && state.searchResponseId ? (
          <NoResults query={query} noResultsText={noResultsText} />
        ) : (
          <ul
            className={classNames('SearchResultList__Layout', {
              CardLayout: layout === 'Card',
              ListLayout: layout === 'List'
            })}
          >
            {state.results.map((result: Result) => (
              <li className='SearchResultList__Item' key={result.uniqueId}>
                {ItemRendering ? (
                  <ItemRendering result={result} engine={engine} language={language} />
                ) : (
                  getResultItem(result, siteName, engine)
                )}
              </li>
            ))}
            <li key='item-1' className='SearchResultList__Item'></li>
            <li key='item-2' className='SearchResultList__Item'></li>
            <li key='item-3' className='SearchResultList__Item'></li>
          </ul>
        )}
      </div>
      {showLoadMore && <LoadMore onClick={loadMore} />}
    </>
  );
};

export default SearchResultList;
