import React, { ComponentType, useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway, useDebounce } from 'react-use';
import SuggestionResultItem from '../SuggestionResultItem';
import { InstantResults, Result, SearchBox } from '@coveo/headless';
import Autosuggest from '../../Common/Autosuggest/Autosuggest';
import SearchIcon from '../../Common/Icons/SearchIcon';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CoveoLoading from '../../Common/Icons/CoveoLoading';
import CoveoMagnifier from '../../Common/Icons/CoveoMagnifier';

const NumberOfMinCharsForInstantSearch = 3;

type PageOmniboxComponentProps = {
  history: RouteComponentProps['history'];
  instantResultsController: InstantResults;
  searchBoxController: SearchBox;
  search: (query: string) => void;
  onSuggestionClick?: (result: Result) => void;
  placeholderText?: string;
};

export const PageOmnibox: React.FC<PageOmniboxComponentProps> = ({
  instantResultsController,
  searchBoxController,
  search,
  onSuggestionClick,
  placeholderText
}) => {
  const [query, setQuery] = useState('');
  const [, cancel] = useDebounce(
    () => {
      if (searchBoxController.state.value !== query) {
        searchBoxController.updateText(query);
      }
    },
    250,
    [query]
  );
  const omniboxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [instantResultsState, setStateInstantResultsState] = useState(instantResultsController.state);
  const [instantResultsOpened, setInstantResultsOpened] = useState(false);

  const handleInstantResultsState = useCallback(() => {
    if (instantResultsController.state.results.length > 0) {
      setInstantResultsOpened(true);
    } else {
      setInstantResultsOpened(false);
    }

    setStateInstantResultsState(instantResultsController.state);
  }, [instantResultsController]);

  useEffect(() => {
    searchBoxController.subscribe(() => {
      setQuery(searchBoxController.state.value);
    });
  }, [searchBoxController]);

  // closing instant results on click outside
  useClickAway(omniboxRef, () => {
    if (instantResultsOpened) {
      setInstantResultsOpened(false);
    }
  });

  // close instant results on empty query
  useEffect(() => {
    if (!query) {
      setInstantResultsOpened(false);
    }
  }, [query]);

  // subscribe to searchbox controller - update instant results controller on searchbox change
  useEffect(
    () =>
      searchBoxController.subscribe(() => {
        // if query is different than searchbox query - it means that standaloneSearchBoxController got it's value form URL query, no need to process anything, as it's not user input
        if (inputRef?.current?.value !== searchBoxController.state.value) {
          return;
        }

        // if query is longer than 3 chars - check instant results state
        if (searchBoxController.state.value.length >= NumberOfMinCharsForInstantSearch) {
          // if query is different than instant results query - update instant results query
          if (instantResultsController.state.q !== searchBoxController.state.value) {
            instantResultsController.updateQuery(searchBoxController.state.value);
          }
        } else {
          // if query is shorter than 3 chars - hide instant results
          setInstantResultsOpened(false);
        }
      }),
    [searchBoxController, instantResultsController, handleInstantResultsState]
  );

  // subscribe to instant results controller - on results toggle instant results flyout
  useEffect(
    () => instantResultsController.subscribe(() => handleInstantResultsState()),
    [instantResultsController, setStateInstantResultsState, handleInstantResultsState]
  );

  // handle clear input
  const clearInput = () => {
    setQuery('');
    cancel();
    search('');
  };

  const clearInputAndFocus = () => {
    clearInput();

    inputRef?.current?.focus();
  };

  // handle input change - ony key down
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const submitSearch = () => {
    if (instantResultsState.results.length > 0) {
      setInstantResultsOpened(false);
    }

    search(query);
  };

  return (
    <div className='PageOmnibox__background-wrapper'>
      <div className='PageOmnibox PageOmnibox--downloads' ref={omniboxRef}>
        <div className='PageOmnibox__wrapper'>
          <Autosuggest
            componentName='PageOmnibox'
            inputIcon={<SearchIcon className='PageOmnibox__search-icon' />}
            handleInputChange={handleInputChange}
            inputRef={inputRef}
            instantResultsOpened={instantResultsOpened}
            instantResultsState={instantResultsState}
            value={query}
            preselectedSuggestion={0}
            minCharsToSearch={NumberOfMinCharsForInstantSearch}
            renderItem={(result: Result, selected: boolean) => (
              <SuggestionResultItem
                selected={selected}
                raw={result.raw}
                onClick={() => {
                  setInstantResultsOpened(false);
                  onSuggestionClick?.(result);
                }}
              />
            )}
            setInstantResultsOpened={setInstantResultsOpened}
            submit={submitSearch}
            clearInputAndFocus={clearInputAndFocus}
            onSelect={(selectedResult: Result) => {
              setInstantResultsOpened(false);
              onSuggestionClick?.(selectedResult);
            }}
            placeholderText={placeholderText || i18n.t('SEARCH | Page Searchbox placeholder')}
          />
          <button
            className='button button--square max-mobile PageOmnibox__submit-search-button'
            onClick={submitSearch}
            aria-label={i18n.t('SEARCH | Searchbox search button')}
          >
            {instantResultsState.isLoading ? (
              <CoveoLoading className='Searchbox__search-button-loading' color='white' />
            ) : (
              <CoveoMagnifier className='Searchbox__search-button-magnifier' />
            )}
          </button>
          <button className='button button--large min-tablet reset-margin' onClick={submitSearch}>
            {i18n.t('SEARCH | Searchbox search button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter<
  RouteComponentProps & PageOmniboxComponentProps,
  ComponentType<RouteComponentProps & PageOmniboxComponentProps>
>(PageOmnibox);
