import React from 'react';
import i18n from 'i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import MetaDataForShare from '../../../MetaDataForShare';

type ProductInfoProps = {
  image: string;
  name: string;
  description: string;
  downloadArticleNumber: string;
  isPhasedOut: boolean;
  isPreLaunch: boolean;
};

const ProductInfo: React.FC<ProductInfoProps> = ({
  image,
  name,
  downloadArticleNumber,
  description,
  isPhasedOut,
  isPreLaunch
}) => {
  return (
    <div className='ProductInfo'>
      <MetaDataForShare
        showPageData
        title={`${i18n.t('PRODUCT_DOWNLOADS | Meta title')} ${name}`}
        currentArticleNumber={downloadArticleNumber.replace('-', '.') || null}
        metaDescription={description}
        image={image}
      />
      {image && (
        <div className='ProductInfo__ImageWrapper'>
          <Image
            className='ProductInfo__Image'
            field={{
              value: {
                src: `${image}?imwidth=560`,
                alt: '',
                title: name
              }
            }}
          />
        </div>
      )}
      <div className='ProductInfo__Text'>
        <h1>
          <span className='ProductInfo__Title '>{i18n.t('PRODUCT_DOWNLOADS | Download page title')}</span>
          <span className='ProductInfo__ProductName'>{name}</span>
        </h1>
        {isPhasedOut && <div className='PhasedOut__Badge'>{i18n.t('PRODUCT_DOWNLOADS | Phased out')}</div>}
        {isPreLaunch && <div className='PreLaunch__Badge'>{i18n.t('PRODUCT_DOWNLOADS | Pre launch')}</div>}
        <p className='ProductInfo__Description'>{description}</p>
      </div>
    </div>
  );
};

export default ProductInfo;
