import React from 'react';
import i18n from 'i18next';
import { getResultImageSrc, getResultTitle, getResultCategory } from '../ResultItemUtils';
import classNames from 'classnames';
import EnterIcon from '../../Common/Icons/EnterIcon';

export const SuggestionInstantResultsProperties = [
  'category',
  'variantarticlenumber',
  'variantarticlenumberraw',
  'variantnametranslated',
  'variantname',
  'imgsrc',
  'mainpicture320',
  'pimclassificationtranslated',
  'masterproductrelativeurl',
  'masterproductname',
  'productassetnamenormalized',
  'ez120xpertisepageherotitle',
  'storypageherotitle',
  'itemurlaz120xetris',
  'itemurlleistergroup',
  'itemurlweldy',
  'itemurlleistertechnologies',
  'solutionpageherotitle',
  'careerz32xpagez32xtitle',
  'universalpageherotitle',
  'title',
  'pimproductid',
  'isphasedout',
  'isprelaunch'
];

type SuggestionResultItemProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  raw: any;
  selectAccessory?: (selectAccessory: string, selectedAccessoryName: string) => void;
  onClick: (selectedAccessoryName: string) => void;
  selected: boolean;
};

export const SuggestionResultItem: React.FC<SuggestionResultItemProps> = ({
  raw,
  selectAccessory,
  onClick,
  selected
}) => {
  const showImage = !!getResultImageSrc(raw);

  return (
    <>
      <input
        type='radio'
        defaultChecked={selected}
        className='visually-hidden'
        name='search'
        value={raw.pimproductid}
        id={raw.pimproductid}
        onClick={() => {
          if (selectAccessory) {
            selectAccessory(raw.pimproductid, getResultTitle(raw));
          }

          onClick(getResultTitle(raw));
        }}
      />
      <div
        className={classNames('SuggestionResultItem', {
          SuggestionResultItem__selected: selected
        })}
        onClick={() => {
          if (selectAccessory) {
            selectAccessory(raw.pimproductid, getResultTitle(raw));
          }

          onClick(getResultTitle(raw));
        }}
      >
        {showImage && (
          <span className='SuggestionResultItem__image'>
            <img src={getResultImageSrc(raw)} alt={getResultTitle(raw)} />
          </span>
        )}
        <label className='SuggestionResultItem__text' htmlFor={raw.pimproductid}>
          <span className='SuggestionResultItem__category'>{getResultCategory(raw)}</span>
          <span className='SuggestionResultItem__title'>{getResultTitle(raw)}</span>
          {raw.isphasedout === 'true' && (
            <span className='PhasedOut__Badge'>{i18n.t('PRODUCT_DOWNLOADS | Phased out')}</span>
          )}
          {raw.isprelaunch === 'true' && (
            <span className='PreLaunch__Badge'>{i18n.t('PRODUCT_DOWNLOADS | Pre launch')}</span>
          )}
        </label>
        <EnterIcon className='SuggestionResultItem__icon' />
      </div>
    </>
  );
};

export default SuggestionResultItem;
