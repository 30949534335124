import React, { ComponentType, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ProductBundlesProps } from './types';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import { getBundleVariantsById } from '../../Shopify/lib/shopify/bundleHelpers';
import BundleDisplay from '../../Shopify/Bundle/BundleDisplay';
import { ShopProductVariantWithProduct, DiscountMetaobject } from '../../Shopify/lib/shopify/types';
import { setAmountOfBundles } from '../../../store/product/actions';
import { getJsonResponse } from '../../../utils/fetch';
import { PRODUCT_ITEMS_TECHNICAL_DETAILS_ENDPOINT } from '../productendpoint';
import { ProductItemTechnicalDetails } from '../types';

const ProductBundles: React.FC<ProductBundlesProps> = ({
  fields,
  selectedVariant,
  setAmountOfBundles,
  sitecoreContext
}) => {
  const { Title, SubTitle } = fields;
  const [technicalDetails, setTechnicalDetails] = React.useState<ProductItemTechnicalDetails[]>([]);
  const [bundles, setBundles] = React.useState<DiscountMetaobject[]>([]);
  const [mainProductVariant, setMainProductVariant] = React.useState<ShopProductVariantWithProduct>();
  const language = sitecoreContext.language;

  const productVariantShopifyIds = React.useMemo(() => {
    return selectedVariant?.shopifyGlobalIds;
  }, [selectedVariant]);

  useEffect(() => {
    async function fetchData() {
      const bundleDataNodes = await getBundleVariantsById(productVariantShopifyIds);

      // Filter condition on main product to only get bundles for new products
      const filteredProductVariantBundleData = bundleDataNodes.filter(
        node => node?.product?.condition?.value !== 'used' && node?.product?.condition?.value !== 'refurbished'
      );
      // Filter by sku, to make sure we have the correct main product variant
      const filteredProductVariantBundleDataBySku = filteredProductVariantBundleData.filter(
        node => node.sku === selectedVariant?.articleNumber
      );

      if (filteredProductVariantBundleDataBySku.length > 1) {
        console.error(
          '[ProductBundles - getBundleVariantsById] Multiple nodes found for the same product (demo device excluded)',
          filteredProductVariantBundleDataBySku
        );
      }

      // We enforce taking the first one as well
      const currentProductVariantBundleData = filteredProductVariantBundleDataBySku[0];
      setMainProductVariant(currentProductVariantBundleData);

      const bundles = currentProductVariantBundleData?.bundleMetafield?.bundles?.nodes ?? [];
      // If items within a bundle are not availableForSale, we don't show the whole bundle
      const bundlesAvailableForSale = bundles.filter(bundle =>
        bundle.productVariants?.references?.nodes?.every(productVariant => productVariant.availableForSale)
      );
      setBundles(bundlesAvailableForSale);

      // Load additional bundle item details, like title and subTitle from product api
      // Get all article numbers of the bundle items across all bundles
      const articleNumbers = bundlesAvailableForSale.flatMap(bundle =>
        bundle.productVariants?.references?.nodes.map(shopProductVariant => shopProductVariant.sku)
      );
      const uniqueArticleNumbers = Array.from(new Set(articleNumbers));

      if (uniqueArticleNumbers.length > 0) {
        const technicalDetailsJson = await getJsonResponse<ProductItemTechnicalDetails[]>(
          PRODUCT_ITEMS_TECHNICAL_DETAILS_ENDPOINT(uniqueArticleNumbers, language)
        );
        setTechnicalDetails(technicalDetailsJson);
      } else {
        setTechnicalDetails([]);
      }

      // Set amount of bundle in redux
      setAmountOfBundles(bundlesAvailableForSale.length ?? 0);
    }

    if (productVariantShopifyIds?.length > 0) {
      fetchData();
    } else {
      // Reset the state
      setMainProductVariant(undefined);
      setBundles([]);
      setAmountOfBundles(0);
    }
  }, [productVariantShopifyIds, setAmountOfBundles]);

  // no render if there are no bundles
  if (bundles.length === 0) {
    return null;
  }

  return (
    <div className='ProductBundles'>
      <div className='componentContainer'>
        <HeadlineWithTitleAndSubtitle extraClasses='ProductBundles-Headline' title={Title} subtitle={SubTitle} />
        <div className='ProductBundles__BundlesContainer'>
          {mainProductVariant &&
            bundles.map((bundle, index) => {
              return (
                <BundleDisplay
                  key={index}
                  items={bundle.productVariants.references.nodes as ShopProductVariantWithProduct[]}
                  bundleName={bundle.discountName.value}
                  baseProductVariant={mainProductVariant}
                  bundleDiscountPercentage={parseFloat(bundle.discountAmount.value)}
                  technicalDetails={technicalDetails}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    amountOfBundles: state.productDetails?.amountOfBundles ?? 0,
    selectedVariant: state.productDetails.selectedVariant
  };
};

const mapDispatchToProps = (dispatch: any): { setAmountOfBundles: (amount: number) => void } => {
  return {
    setAmountOfBundles: (amount: number) => dispatch(setAmountOfBundles(amount))
  };
};

const hasBundles = function (props: ProductBundlesProps) {
  return props.amountOfBundles > 0;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter<RouteComponentProps & ProductBundlesProps, ComponentType<RouteComponentProps & ProductBundlesProps>>(
    withSitecoreContext()(WithStickyMenu(ProductBundles, hasBundles))
  )
);
