import { reshapeProducts } from '.';
import { ProductItem } from '../../../Product/types';
import { ProductVariantSelectorData } from '../../ProductVariantSelector';
import { TAGS } from '../constants';
import { getProductVariantsByGidsQuery } from './queries/product';
import { shopifyFetch } from './shopifyFetch';
import { ShopProduct, ShopProductVariant, ShopifyProductVariantNodesOperation } from './types';

// Simple implementation which allows us to use a ENV variable to enable/disable debug logging
const DEBUG = process.env.REACT_APP_ENABLE_DEBUG || false;

/**
 * Returns unique Shopify global ids from product items.
 *
 * This function takes an array of `ProductItem` objects, each expected to have a `shopifyGlobalIds` property
 * containing an array of strings. It extracts all Shopify global IDs from these objects, removes any duplicates,
 * and returns an array of unique IDs. This is useful for operations requiring a list of distinct Shopify product
 * identifiers.
 *
 * @param productItems An array of `ProductItem` objects. Each `ProductItem` must have a `shopifyGlobalIds` property,
 *                     which is an array of string IDs.
 * @returns An array of strings, each representing a unique Shopify global ID extracted from the input `productItems`.
 */
export const getAllShopifyGlobalIdsFromProductItems = (productItems: ProductItem[]): string[] => {
  if (productItems?.length === 0) return [];

  const shopifyGlobalIds: string[] = productItems
    .flatMap((productItem: ProductItem) => productItem?.shopifyGlobalIds)
    .filter((gid: string) => !!gid);
  const uniqueShopifyGlobalIds = Array.from(new Set(shopifyGlobalIds));
  return uniqueShopifyGlobalIds;
};

/**
 * Fetches shop products by their variant GIDs.
 *
 * This function queries the Shopify API to retrieve product variants based on the provided GIDs,
 * reshapes the product data, and returns the reshaped products.
 *
 * ATTENTION: Do not call this function, if the shop should not be rendered!
 * We prefer to check this outside of this function to avoid unnecessary complexity.
 *
 * @param {string[]} gids - An array of product variant GIDs.
 * @returns {Promise<ShopProduct[]>} A promise that resolves to an array of reshaped shop products.
 */
export async function getShopProductsByVariantGids(gids: string[]): Promise<ShopProduct[]> {
  if (gids?.length === 0) return [];

  const response = await shopifyFetch<ShopifyProductVariantNodesOperation>({
    query: getProductVariantsByGidsQuery(),
    tags: [TAGS.products],
    variables: { query: gids }
  });

  const reshapedProducts = extractAndReshapeProductsFromProductVariantsResponse(response.body);

  return reshapedProducts;
}

export function extractAndReshapeProductsFromProductVariantsResponse(
  body: Pick<ShopifyProductVariantNodesOperation, 'data'>
): ShopProduct[] {
  const products = body.data.nodes.map(productVariant => productVariant.product);
  DEBUG && console.log('[Shopify] getProductVariantsByGids - products: ', products);
  const reshapedProducts = reshapeProducts(products);
  DEBUG && console.log('[Shopify] getProductVariantsByGids - reshapedProducts: ', reshapedProducts);
  return reshapedProducts;
}

/**
 * Filters the shop products based on their condition.
 *
 * @param {ShopProduct[]} shopProducts - The array of shop products to filter.
 * @param {boolean} used - A flag indicating whether to filter for used products.
 * @returns {ShopProduct[]} The filtered array of shop products.
 */
export const filterShopProducts = (shopProducts: ShopProduct[], used: boolean): ShopProduct[] => {
  return shopProducts.filter(shopProduct =>
    used ? shopProduct?.condition?.value === 'used' : shopProduct?.condition?.value !== 'used'
  );
};

/**
 * Filters the given shop products to return only those that are used.
 *
 * @param {ShopProduct[]} shopProducts - The array of shop products to filter.
 * @returns {ShopProduct[]} - The filtered array of used shop products.
 */
export const getShopProductsConditionUsed = (shopProducts: ShopProduct[]): ShopProduct[] => {
  return filterShopProducts(shopProducts, true);
};

/**
 * Filters the given shop products to return only those that are not in used condition.
 *
 * @param {ShopProduct[]} shopProducts - The array of shop products to filter.
 * @returns {ShopProduct[]} - The filtered array of shop products in new condition.
 */
export const getShopProductsConditionNew = (shopProducts: ShopProduct[]): ShopProduct[] => {
  return filterShopProducts(shopProducts, false);
};

/**
 * Returns the shop products that contain a variant with the given SKU.
 *
 * @param {ShopProduct[]} shopProducts - The array of shop products to search.
 * @param {string} sku - The SKU to search for.
 * @returns {ShopProduct[]} - The array of shop products that contain a variant with the given SKU.
 */
export const getShopProductsBySku = (shopProducts: ShopProduct[], sku: string): ShopProduct[] => {
  return shopProducts.filter(shopProduct => shopProduct.variants.some(variant => variant.sku === sku));
};

/**
 * Filters out any products that do not have a variant with a matching SKU
 * Within a product it also removes the product variants that do not have a matching SKU
 *
 * @param {ShopProduct[]} products - The list of products to filter.
 * @param {string | string[]} sku - The SKU or list of SKUs to filter by.
 * @returns {ShopProduct[]} - The filtered list of products with matching SKUs.
 */
export const filterProductsBySkuAndReduceProductVariants = (
  products: ShopProduct[],
  sku: string | string[]
): ShopProduct[] => {
  if (!products || !sku) return products ?? [];

  const skusToCheck = Array.isArray(sku) ? sku : [sku];

  const productsWithMatchingSku: ShopProduct[] = [];
  products.forEach(product => {
    let productVariantsFilteredBySku: ShopProductVariant[] = [];
    // Check for matching SKU
    productVariantsFilteredBySku = product.variants.filter(
      (variant: ShopProductVariant) => variant.sku && skusToCheck.includes(variant.sku)
    );

    // If we have a matching variant, add it to the list
    if (productVariantsFilteredBySku.length) {
      productsWithMatchingSku.push({ ...product, variants: productVariantsFilteredBySku });
    }
  });
  DEBUG && console.log('[Shopify] productsWithMatchingSku', productsWithMatchingSku);
  return productsWithMatchingSku;
};

/**
 * Filters the given list of products to include only those that have at least one variant available for sale.
 *
 * @param {ShopProduct[]} products - The list of products to filter.
 * @returns {ShopProduct[]} - The filtered list of products with at least one variant available for sale.
 */
export const filterProductsByAvailableForSale = (products: ShopProduct[]): ShopProduct[] => {
  if (!products) return products ?? [];

  const productsAvailableForSale: ShopProduct[] = [];
  products.forEach(product => {
    // Filter out any variants that are not available for sale
    const productVariantsAvailableForSale = product.variants.filter(
      (variant: ShopProductVariant) => variant.availableForSale
    );
    // Collect all the products that have at least one variant available for sale
    if (productVariantsAvailableForSale.length) {
      productsAvailableForSale.push({ ...product, variants: productVariantsAvailableForSale });
    }
  });
  DEBUG && console.log('productsAvailableForSale', productsAvailableForSale);
  return productsAvailableForSale;
};

export const getUniqueShopProductVariantsFromShopProducts = (shopProducts: ShopProduct[]): ShopProductVariant[] => {
  if (!shopProducts) return [];

  const uniqueShopProductVariants: ShopProductVariant[] = [];
  shopProducts.forEach(shopProduct => {
    shopProduct.variants.forEach(variant => {
      if (!uniqueShopProductVariants.some(v => v.id === variant.id)) {
        uniqueShopProductVariants.push(variant);
      }
    });
  });

  return uniqueShopProductVariants;
};

export const sortProductsByAvailability = (
  a: ShopProductVariant | undefined,
  b: ShopProductVariant | undefined
): number => {
  const isAInOnlineShop = !!a?.id;
  const isBInOnlineShop = !!b?.id;

  const isACurrentlyNotInStock = a?.currentlyNotInStock && a?.availableForSale;
  const isAAvailable = a?.availableForSale;
  const isANotAvailable = !a?.availableForSale && !a?.currentlyNotInStock;

  const isBCurrentlyNotInStock = b?.currentlyNotInStock && b?.availableForSale;
  const isBAvailable = b?.availableForSale;
  const isBNotAvailable = !b?.availableForSale && !b?.currentlyNotInStock;

  // if both are in shop
  if (isAInOnlineShop === isBInOnlineShop) {
    // if a is avaible it should be first
    if (isAAvailable) {
      return -1;
    }

    // if b is avaible it should be first
    if (isBAvailable) {
      return 1;
    }

    // if both are not available and a is not currently not in stock it should be first
    if (isACurrentlyNotInStock) {
      return -1;
    }

    // if both are not available and b is not currently not in stock it should be first
    if (isBCurrentlyNotInStock) {
      return 1;
    }

    // if both are not available and a is not available it should be first
    if (isANotAvailable) {
      return -1;
    }

    // if both are not available and b is not available it should be first
    if (isBNotAvailable) {
      return 1;
    }
  }

  return isAInOnlineShop ? -1 : 1;
};

export const sortProductsBySKU = (a: ProductVariantSelectorData, b: ProductVariantSelectorData): number => {
  return b.articleNumber.localeCompare(a.articleNumber);
};

export const getProductItemDetailPageUrl = (
  assetNameNormalized: string,
  sku: string | null,
  language = 'en'
): string => {
  const skuUrl = sku && sku.replace('.', '-');
  return `/${language}/product/${assetNameNormalized}/${skuUrl}`;
};

export const sortShopProductVariantsByPrice = (shopProductVariants: ShopProductVariant[]): ShopProductVariant[] => {
  if (!shopProductVariants) return [];

  const sortedShopProductVariants = shopProductVariants.sort((a, b) => {
    const priceA = parseFloat(a?.price?.amount);
    const priceB = parseFloat(b?.price?.amount);

    return priceA - priceB;
  });

  return sortedShopProductVariants;
};
