import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import { MASTER_DOWNLOADS_ENDPOINT, SHORT_PRODUCT_ENDPOINT } from '../productendpoint';
import { setAllDownloads, shortProductData } from '../../../store/product/actions';
import { checkIfUserIsAllowedToAccessPreLaunch, checkIfUserIsLoggedIn } from '../../../utils/Utils';
import i18n from 'i18next';
import ProductInfo from './ProductInfo';
import { DownloadType, AllDownloadsProps, DownloadItem } from './types';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle';
import DownloadTabs from './DownloadTabs';
import DownloadsFilters from './DownloadsFilters';
import DownloadItems from './DownloadItems';
import DownloadsBackLink from './DownloadsBackLink';
import getDownloadListData from './getDownloadListData';
import FileSaver from 'file-saver';
import Button from '../../Common/Button/Button';
import { getArticleNumber, getProductName } from './getDownloadsProductAndArticle';
import { useDesktopDevice } from '../../../hooks/useDevice';
import classNames from 'classnames';

const AllDownloads: React.FC<AllDownloadsProps> = ({
  sitecoreContext,
  allDownloads,
  setAllDownloads,
  setProductJson,
  match,
  fields,
  productName,
  productDescription,
  productImage,
  shortProductDataLoading,
  shortProductDataLoaded,
  allDownloadsLoading,
  allDownloadsLoaded,
  isPhasedOut,
  isPreLaunch
}) => {
  const isUserLoggedIn = useMemo(() => checkIfUserIsLoggedIn(sitecoreContext), [sitecoreContext]);
  const isUserAllowedToAccessPreLaunch = useMemo(
    () => checkIfUserIsAllowedToAccessPreLaunch(sitecoreContext),
    [sitecoreContext]
  );
  const hasTabs = !fields?.['Merge Tabs']?.value && ((isPhasedOut && isUserLoggedIn) || !isPhasedOut);
  const hasProductDetails = fields?.['Show Product Details']?.value;
  const language = useMemo(() => getLanguage(sitecoreContext), [sitecoreContext]);
  const siteName = sitecoreContext?.site?.name;
  const [selectedTab, setSelectedTab] = useState<DownloadType>(hasTabs ? 'info' : 'all');
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const downloadList = useMemo(
    () => getDownloadListData(selectedTab, allDownloads, isUserLoggedIn, currentCategory, currentLanguage),
    [selectedTab, allDownloads, isUserLoggedIn, currentCategory, currentLanguage]
  );
  const [isLanguagePreselected, setIsLanguagePreselected] = useState(false);
  const AllLabel = i18n.t('PRODUCT_DOWNLOADS | All');
  const downloadPagePath = sitecoreContext?.productDownloads?.productDownloadsDetailPage || '';
  const [bulkDownloadItems, setBulkDownloadItems] = useState<DownloadItem[]>([]);
  const [downloadProductName, setDownloadProductName] = useState(getProductName(match, downloadPagePath));
  const [downloadArticleNumber, setDownloadArticleNumber] = useState(getArticleNumber(match, downloadPagePath));
  const isBulkDownloadAllowed = useDesktopDevice();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const filteredItemsLength = downloadList?.filteredItems?.length ?? 0;

  const setAllDownloadsOnRedux = useCallback(
    (currentSiteLanguage: string) => {
      if (
        downloadProductName &&
        `${downloadProductName}${downloadArticleNumber}` !== allDownloadsLoading &&
        `${downloadProductName}${downloadArticleNumber}` !== allDownloadsLoaded &&
        'error' !== allDownloadsLoaded
      ) {
        setAllDownloads(downloadProductName, downloadArticleNumber, currentSiteLanguage);
      }
    },
    [downloadProductName, downloadArticleNumber, setAllDownloads, allDownloadsLoading, allDownloadsLoaded]
  );

  const setProductsOnRedux = useCallback(
    (currentSiteLanguage: string, currentSiteName: string) => {
      if (
        downloadProductName &&
        `${downloadProductName}${downloadArticleNumber}` !== shortProductDataLoading &&
        `${downloadProductName}${downloadArticleNumber}` !== shortProductDataLoaded &&
        'error' !== shortProductDataLoaded
      ) {
        setProductJson(downloadProductName, downloadArticleNumber, currentSiteLanguage, currentSiteName);
      }
    },
    [downloadProductName, downloadArticleNumber, setProductJson, shortProductDataLoading, shortProductDataLoaded]
  );

  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem('mydevices');
    };
  }, []);

  useEffect(() => {
    setDownloadProductName(getProductName(match, downloadPagePath));
    setDownloadArticleNumber(getArticleNumber(match, downloadPagePath));
  }, [match, downloadPagePath]);

  useEffect(() => {
    const currentSiteLanguage = getLanguage(sitecoreContext);

    if (currentSiteLanguage && downloadList?.languages?.length && !isLanguagePreselected) {
      const currentLanguageElement = downloadList.languages.find(
        element => element.languageShort === currentSiteLanguage
      );
      const newCurrentLanguage = currentLanguageElement?.language ? currentLanguageElement.language : AllLabel;

      setCurrentLanguage(newCurrentLanguage);
      setIsLanguagePreselected(true);
    }
  }, [sitecoreContext, downloadList, setAllDownloadsOnRedux, isLanguagePreselected, setProductsOnRedux, AllLabel]);

  useEffect(() => {
    setAllDownloadsOnRedux(language);
    setProductsOnRedux(language, siteName);

    setIsLanguagePreselected(false);
  }, [language, siteName, setAllDownloadsOnRedux, setProductsOnRedux]);

  useEffect(() => {
    setBulkDownloadItems(bulkDownloadItems =>
      bulkDownloadItems.filter(item => downloadList?.filteredItems?.some(downloadItem => downloadItem.url === item.url))
    );
  }, [downloadList]);

  const changeTab = (tabKey: DownloadType) => {
    setSelectedTab(tabKey);
    setCurrentCategory(AllLabel);
    setCurrentLanguage(AllLabel);
    setBulkDownloadItems([]);
  };

  const toggleItemInBulkDownload = (url: string, checked: boolean) => {
    if (checked) {
      const item = downloadList?.filteredItems?.find(item => item.url === url);
      const isItemInBulkDownload = bulkDownloadItems.some(item => item.url === url);

      if (item && !isItemInBulkDownload) {
        setBulkDownloadItems(bulkDownloadItems => [...bulkDownloadItems, item]);
      }
    } else {
      setBulkDownloadItems(bulkDownloadItems => bulkDownloadItems.filter(item => item.url !== url));
    }
  };

  const bulkDownload = () => {
    if (bulkDownloadItems.length) {
      downloadMultipleFiles(bulkDownloadItems.length - 1);
    }
  };

  useEffect(() => {
    const filteredItems = downloadList?.filteredItems ?? [];
    setSelectAllChecked(filteredItems.every(item => bulkDownloadItems?.some(bulkItem => bulkItem.url === item.url)));
  }, [downloadList, bulkDownloadItems, currentLanguage]);

  const toggleAllItems = (checked: boolean) => {
    const filteredItems = downloadList?.filteredItems ?? [];
    setBulkDownloadItems(checked ? filteredItems : []);
  };

  const downloadMultipleFiles = (fileIndex: number) => {
    setTimeout(function () {
      const file = bulkDownloadItems?.[fileIndex];

      if (file) {
        fetch(file.url)
          .then(res => res.blob())
          .then(blob => {
            FileSaver.saveAs(
              new Blob([blob], {
                type: 'application/octet-stream'
              }),
              `${file.title}.${file.extension}`
            );
          });
      }

      if (fileIndex > 0) {
        downloadMultipleFiles(fileIndex - 1);
      }
    }, 1000); // if less than 1000, not all files are downloaded in iOS
  };

  if (!fields || !downloadList) {
    return <div className='componentContainer ' />;
  }

  const {
    'My Devices Link': MyDevicesLink,
    'Product Downloads Lister Link': ProductDownloadsListerLink,
    Title,
    Subtitle
  } = fields;

  // if there's nothing to download
  if (
    (allDownloads?.all?.items.length || 0) +
      (allDownloads?.info?.items.length || 0) +
      (allDownloads?.media?.items.length || 0) ===
    0
  ) {
    return null;
  }

  return (
    <div
      className={classNames('AllDownloads click_productdetail_block_downloads', {
        BulkDownloadAllowed: isBulkDownloadAllowed
      })}
    >
      <div className='AllDownloads-Header-Wrapper componentContainer'>
        {hasProductDetails ? (
          <>
            {(MyDevicesLink?.value || ProductDownloadsListerLink?.value) && (
              <DownloadsBackLink
                fields={fields}
                downloadProductName={downloadProductName}
                downloadArticleNumber={downloadArticleNumber}
                language={language}
              />
            )}
            <ProductInfo
              image={productImage}
              name={productName}
              downloadArticleNumber={downloadArticleNumber}
              description={productDescription}
              isPhasedOut={isPhasedOut}
              isPreLaunch={isPreLaunch}
            />
          </>
        ) : (
          <HeadlineWithTitleAndSubtitle extraClasses='ProductDownloads-Headline' title={Title} subtitle={Subtitle} />
        )}
        {hasTabs && (
          <DownloadTabs
            selectedTab={selectedTab}
            changeTab={changeTab}
            allDownloads={allDownloads}
            isUserLoggedIn={isUserLoggedIn}
          />
        )}
      </div>
      {((isPhasedOut && isUserLoggedIn) ||
        (isPreLaunch && isUserAllowedToAccessPreLaunch) ||
        (!isPhasedOut && !isPreLaunch)) && (
        <div className='AllDownloads-Content-Wrapper'>
          <div className='AllDownloads-Content componentContainer'>
            <div className='AllDownloads-Content-Header'>
              <DownloadsFilters
                downloadList={downloadList}
                currentLanguage={currentLanguage}
                setCurrentLanguage={setCurrentLanguage}
                currentCategory={currentCategory}
                setCurrentCategory={setCurrentCategory}
              />
              {isBulkDownloadAllowed && bulkDownloadItems.length > 0 && (
                <div className='AllDownloads-Bulk-Downloads'>
                  <Button
                    onClick={bulkDownload}
                    buttonTitle={i18n.t('PRODUCT_DOWNLOADS | Bulk download')}
                    beforeIcon='icon-web-24-download'
                    variant='secondary'
                    size='large'
                  />
                </div>
              )}
            </div>
            {isBulkDownloadAllowed && !!filteredItemsLength && (
              <label className='AllDownloads-Select-All'>
                <input
                  type='checkbox'
                  onChange={event => {
                    setSelectAllChecked(event.target.checked);
                    toggleAllItems(event.target.checked);
                  }}
                  checked={selectAllChecked}
                />
                <span className='checkmark'></span>
                <span>{i18n.t('PRODUCT_DOWNLOADS | Select all')}</span>
                <span>&nbsp;({filteredItemsLength})</span>
              </label>
            )}
            <DownloadItems
              currentLanguage={currentLanguage}
              downloadList={downloadList}
              bulkDownloadItems={bulkDownloadItems}
              toggleItemInBulkDownload={toggleItemInBulkDownload}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { productDetails } = state;
  const {
    shortMasterProductData,
    shortProductDataLoading,
    shortProductDataLoaded,
    allDownloadsLoading,
    allDownloadsLoaded
  } = productDetails;

  return {
    allDownloads: productDetails.allDownloads.items,
    productTitle: productDetails.allDownloads.productName,
    productName: shortMasterProductData ? shortMasterProductData.name : null,
    productDescription: shortMasterProductData ? shortMasterProductData.description : null,
    productImage: shortMasterProductData ? shortMasterProductData.image : null,
    isPhasedOut: shortMasterProductData ? shortMasterProductData.isPhasedOut : false,
    isPreLaunch: shortMasterProductData ? shortMasterProductData.isPreLaunch : false,
    shortProductDataLoading,
    shortProductDataLoaded,
    allDownloadsLoading,
    allDownloadsLoaded
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setProductJson: (product_name: string, downloadArticleNumber: string, language: string, tenant: string) =>
      dispatch(
        shortProductData(
          `${product_name}${downloadArticleNumber}`,
          SHORT_PRODUCT_ENDPOINT(product_name, downloadArticleNumber, language, tenant)
        )
      ),
    setAllDownloads: (product_name: string, article_number: string, language: string) =>
      dispatch(
        setAllDownloads(
          `${product_name}${article_number ?? ''}`,
          MASTER_DOWNLOADS_ENDPOINT(product_name, article_number, language)
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter<RouteComponentProps & AllDownloadsProps, ComponentType<RouteComponentProps & AllDownloadsProps>>(
    withSitecoreContext()(AllDownloads)
  )
);
