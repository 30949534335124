import { setDiscountCodesOnCart } from './index';
import { TAGS } from '../constants';
import { getBundleDataByIdQuery } from './queries/bundle';
import { shopifyFetch } from './shopifyFetch';
import { Cart, ShopifyBundleDataNode, ShopifyBundleDataOperation, ShopifyDiscountCodeError } from './types';

// Simple implementation which allows us to use a ENV variable to enable/disable debug logging
const DEBUG = process.env.REACT_APP_ENABLE_DEBUG || false;

export async function getBundleVariantsById(gids: string | string[]): Promise<ShopifyBundleDataNode[]> {
  const ids = Array.isArray(gids) ? gids : [gids];
  if (ids.length === 0) return [];

  const res = await shopifyFetch<ShopifyBundleDataOperation>({
    query: getBundleDataByIdQuery(),
    tags: [TAGS.products],
    variables: { ids }
  });

  const bundles = res?.body?.data?.nodes ?? [];
  DEBUG && console.log('[bundleHelpers] getBundleDataById: ', bundles);

  return bundles;
}

// Helper function to apply discount to the cart
export async function applyDiscountToCart(
  cart: Cart,
  bundleDiscountCode: string,
  removeCode: boolean = false
): Promise<{
  cart: Cart;
  userErrors: ShopifyDiscountCodeError[];
  removeCode?: boolean;
}> {
  let discountsToApply: string[];

  if (removeCode) {
    // Remove the discount code
    discountsToApply =
      cart.discountCodes?.map(discount => discount.code).filter(code => code !== bundleDiscountCode) ?? [];
  } else {
    // Add the discount code
    const existingDiscounts: string[] = cart?.discountCodes?.map(discount => discount.code) ?? [];
    discountsToApply = Array.from(new Set([...existingDiscounts, bundleDiscountCode]));
  }

  // TODO LAGWEB-2071: Think / ask about event tracking for discount codes

  DEBUG && console.log('[bundleHelpers] applyDiscountToCart - discountsToApply: ', discountsToApply);
  return await setDiscountCodesOnCart(cart.id, discountsToApply);
}
